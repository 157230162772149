import Footer from './Footer'
//css
import '../assets/css/Contacto.css';
function Contact(){
    return(
        <main>
            <div className='white-section'>  
                <h1>Contacto</h1>
            </div>
            <svg className='wave' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#0099ff" fillOpacity="1" d="M0,160L48,170.7C96,181,192,203,288,213.3C384,224,480,224,576,192C672,160,768,96,864,90.7C960,85,1056,139,1152,138.7C1248,139,1344,85,1392,58.7L1440,32L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
            <div className='blue-section-contact' >
                <h1>info@truechoice.mx</h1>
            </div>
            <div>
                <Footer/>
            </div>
        </main>
    );
};
export default Contact;