//boostrap
import ContainerCards from "./ContainerCards";
import { Col, Row } from "react-bootstrap";
//css
import '../assets/css/homepage.css'
//components
import Footer from "./Footer";
import OneCards from "./OneCards";
function HomePage(){
    return(
        <div className="homepage-main">
            <header>
                <div className="header-container">
                    <h1>True choice</h1>
                </div>
            </header>
            <section className="main-section">
                <div className="header">
                    <h2>The Best To Choice</h2>
                </div>
                <div>
                    <ContainerCards/>
                </div>
            </section>
            <section className="stadistics">
            <Row className="stadistics">
                    <Col className="stadistics">
                    <h2>
                        <svg className="icon w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                            <path fillRule="evenodd" d="M12 2c-.791 0-1.55.314-2.11.874l-.893.893a.985.985 0 0 1-.696.288H7.04A2.984 2.984 0 0 0 4.055 7.04v1.262a.986.986 0 0 1-.288.696l-.893.893a2.984 2.984 0 0 0 0 4.22l.893.893a.985.985 0 0 1 .288.696v1.262a2.984 2.984 0 0 0 2.984 2.984h1.262c.261 0 .512.104.696.288l.893.893a2.984 2.984 0 0 0 4.22 0l.893-.893a.985.985 0 0 1 .696-.288h1.262a2.984 2.984 0 0 0 2.984-2.984V15.7c0-.261.104-.512.288-.696l.893-.893a2.984 2.984 0 0 0 0-4.22l-.893-.893a.985.985 0 0 1-.288-.696V7.04a2.984 2.984 0 0 0-2.984-2.984h-1.262a.985.985 0 0 1-.696-.288l-.893-.893A2.984 2.984 0 0 0 12 2Zm3.683 7.73a1 1 0 1 0-1.414-1.413l-4.253 4.253-1.277-1.277a1 1 0 0 0-1.415 1.414l1.985 1.984a1 1 0 0 0 1.414 0l4.96-4.96Z" clipRule="evenodd"/>
                        </svg>
                        1M+
                    </h2>
                    <p>Visitas por mes</p></Col>
                    <Col className="stadistics">
                    <h2>
                        <svg className="icon w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                            <path d="m12.75 20.66 6.184-7.098c2.677-2.884 2.559-6.506.754-8.705-.898-1.095-2.206-1.816-3.72-1.855-1.293-.034-2.652.43-3.963 1.442-1.315-1.012-2.678-1.476-3.973-1.442-1.515.04-2.825.76-3.724 1.855-1.806 2.201-1.915 5.823.772 8.706l6.183 7.097c.19.216.46.34.743.34a.985.985 0 0 0 .743-.34Z"/>
                        </svg>
                        93%
                    </h2><p>De satisfaccion con los clientes</p></Col>
                    <Col className="stadistics">
                    <h2>
                        <svg className="icon w-[40px] h-[40px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                            <path d="M8.597 3.2A1 1 0 0 0 7.04 4.289a3.49 3.49 0 0 1 .057 1.795 3.448 3.448 0 0 1-.84 1.575.999.999 0 0 0-.077.094c-.596.817-3.96 5.6-.941 10.762l.03.049a7.73 7.73 0 0 0 2.917 2.602 7.617 7.617 0 0 0 3.772.829 8.06 8.06 0 0 0 3.986-.975 8.185 8.185 0 0 0 3.04-2.864c1.301-2.2 1.184-4.556.588-6.441-.583-1.848-1.68-3.414-2.607-4.102a1 1 0 0 0-1.594.757c-.067 1.431-.363 2.551-.794 3.431-.222-2.407-1.127-4.196-2.224-5.524-1.147-1.39-2.564-2.3-3.323-2.788a8.487 8.487 0 0 1-.432-.287Z"/>
                        </svg>
                        #1
                    </h2><p>En ventas a nivel nacional</p></Col>
                </Row>
                {/* SECCION DE SERVICIOS */}
                <Row className="stadistics">
                    <Col sm={4}>
                        <div className="space-container">
                            <div className="center-container"><h2>FABRICACION</h2></div>
                        </div>
                    </Col>
                    <Col sm={8}>
                        <Row>
                            <Col className="services">
                            <div className="space-container">
                                <div>
                                    <h2 className="servicios-main">SERVICIOS</h2>
                                </div>
                            </div>
                            <div className="space-container">
                                <div className="center-container"><h2>Importacion<br/> Exportacion</h2></div>
                            </div>
                            </Col>
                            <Col>
                            <div className="space-container">
                                <div className="center-container">
                                    <h2>PERSONALIZACION</h2>
                                </div>
                            </div>
                            </Col>
                        </Row>
                        <Row>
                            <div className="center-container"> <h2>COMERCIALIZACION</h2></div>
                        </Row>
                    </Col>
                    
                </Row> 

                
            </section>
            <section className="d-one-section">
                <Row className="d-one-info">
                    <Col>
                        <OneCards/>
                    </Col>
                    <Col className="oneinfo">
                        <h1>The one</h1>
                        <h4>Donde la excenlencia y el rendimiento se encuentran</h4>
                        <p>Bienvenidos a The One, tu destino definitivo para ropa 
                        deportiva y equipamiento especializado en fútbol americano. 
                        Diseñamos y fabricamos productos de alta calidad para atletas 
                        que buscan maximizar su rendimiento en el campo. Nuestra 
                        misión es ofrecerte lo mejor en tecnología, confort y estilo, para 
                        que puedas concentrarte en lo que realmente importa: ¡el juego!</p>
                    </Col>
                </Row>
            </section>
            <footer>
                <Footer/>
            </footer>
        </div>
    )
}
export default HomePage;