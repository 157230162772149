import { Col, Row } from 'react-bootstrap';
import React from 'react'
import ReactPlayer from 'react-player/lazy'
import Footer from './Footer';
import MainCard from './MainCard';
import VideoComponent from './Video';
import '../assets/css/nosotros.css'
function Nosotros(){
    return(
        <main>
        <section>
            <div className='white-section'>
                <h1>Nosotros</h1>
            </div>
                <svg className='wave' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#0099ff" fillOpacity="1" d="M0,192L21.8,213.3C43.6,235,87,277,131,293.3C174.5,309,218,299,262,282.7C305.5,267,349,245,393,240C436.4,235,480,245,524,213.3C567.3,181,611,107,655,101.3C698.2,96,742,160,785,192C829.1,224,873,224,916,202.7C960,181,1004,139,1047,144C1090.9,149,1135,203,1178,208C1221.8,213,1265,171,1309,149.3C1352.7,128,1396,128,1418,128L1440,128L1440,320L1418.2,320C1396.4,320,1353,320,1309,320C1265.5,320,1222,320,1178,320C1134.5,320,1091,320,1047,320C1003.6,320,960,320,916,320C872.7,320,829,320,785,320C741.8,320,698,320,655,320C610.9,320,567,320,524,320C480,320,436,320,393,320C349.1,320,305,320,262,320C218.2,320,175,320,131,320C87.3,320,44,320,22,320L0,320Z"></path></svg>
            <div className='blue-section'>
            <div>
                <h1>Nuestra Historia</h1>
                <p>The One nació de la pasión por los deportes y la búsqueda de la excelencia en el rendimiento deportivo. Fundada en 2024, nuestra marca ha crecido desde sus humildes comienzos hasta convertirse en un referente en el mundo del fútbol americano. Inspirándonos en la dedicación y el espíritu competitivo de los atletas, nos comprometemos a ofrecer productos de alta calidad que mejoren su rendimiento y mejoren su experiencia en el campo.</p>
            </div>
            <div>
                <Row>
                    <Col xs={3} className='main-col-left'>
                    <MainCard
                    URL={"https://truechoice.mx/public/img/nosotrostres.jpg"}/>
                    </Col>
                    <Col className='main-col-rigth'>
                        <Row>
                            <section className='top'>
                                <Row>
                                    <Col className='top-left-col'>
                                    <MainCard
                                    URL={"https://truechoice.mx/public/img/nosotrosdos.jpg"}/>
                                    </Col>
                                    <Col className='top-right-col'>
                                    <MainCard
                                    URL={"https://truechoice.mx/public/img/nosotrosuno.jpg"}/>
                                    </Col>
                                </Row>
                            </section>
                            <section className='bottom'>
                                <h1>Mision</h1>
                                <p>The One nació de la pasión por los deportes y la búsqueda de la excelencia en el rendimiento deportivo. Fundada en 2024, nuestra marca ha crecido desde sus humildes comienzos hasta convertirse en un referente en el mundo del fútbol americano. Inspirándonos en la dedicación y el espíritu competitivo de los atletas, nos comprometemos a ofrecer productos de alta calidad que mejoren su rendimiento y mejoren su experiencia en el campo.</p>
                            </section>
                        </Row>
                     </Col>
                </Row>
                
            </div >
                <div className='video-container'>
                <ReactPlayer url='https://www.youtube.com/embed/Q-SEaqvjNms?si=5R4oG2ftaGiVxBKx' 
                controls/> 
                </div>
            </div>
        </section>
        <footer>
            <Footer/>
        </footer>
    </main>
    );
};

export default Nosotros;