import { Link } from 'react-router-dom';
import { Nav, Navbar, Container } from 'react-bootstrap';

import '../assets/css/navBar.css'
function NavBar() {
  return (
    
    <Navbar collapseOnSelect expand="lg" className="navbar-transparent fixed-top">
    <Container>
      <Navbar.Brand as={Link} to="/">
        <img
          src="https://truechoice.mx/public/img/colord1.png"
          className="d-inline-block align-top"
          alt="TheOne"/>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto">
          {/* Agregar enlaces adicionales aquí si es necesario */}
        </Nav>
        <Nav>
          <Nav.Link as={Link} to="/Nosotros">Nosotros</Nav.Link>
          <Nav.Link as={Link} to="/Servicios">Servicios</Nav.Link>
          <Nav.Link as={Link} to="/Contacto">Contacto</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>

      
  );
}

export default NavBar;
