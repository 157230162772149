import { Col,Row } from "react-bootstrap";
//components
import Footer from "./Footer";
import CardService from "./CardService";
//css
import '../assets/css/services.css';
function Servicios(){
    return(
        <main>
            
            <section>
                <div className="white-section-services">
                    <h1>Servicios</h1>
                </div>
                <svg className="wave" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                    <path fill="#30cfd0" fillOpacity="1" d="M0,224L30,218.7C60,213,120,203,180,186.7C240,171,300,149,360,128C420,107,480,85,540,96C600,107,660,149,720,170.7C780,192,840,192,900,176C960,160,1020,128,1080,138.7C1140,149,1200,203,1260,202.7C1320,203,1380,149,1410,122.7L1440,96L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"></path>
                </svg>
                <div className="blue-section-services">
                    <Row>
                        <Col className="containerCard">
                            <CardService
                            Title={"Fabricacion"}
                            FirstPart={"Nuestra experiencia en fabricación nos permite producir una amplia gama de productos de fútbol americano, incluidos uniformes, cascos, artículos de entrenamiento en el campo y souvenirs. Utilizamos materiales de alta calidad y técnicas avanzadas para garantizar que cada pieza sea duradera y funcional, brindando a los atletas el equipo que necesitan para rendir al máximo."}
                            SecondPart={"¿Lo que ofrecemos?"}
                            LastPart={"Uniformes de Fútbol Americano: Confeccionados con tejidos de alta tecnología para mayor durabilidad y comodidad.Cascos y equipo de protección: Diseñados para la seguridad y el rendimiento.Suministros de entrenamiento de campo: incluidos conos, escaleras de agilidad y más. Souvenirs y Merchandising: Productos personalizados para fans y seguidores."}
                            URL={"https://truechoice.mx/public/img/fabricacion.webp"}/>
                        </Col>
                        <Col className="card-container">
                        <CardService
                            Title={"Comercializacion"}
                            FirstPart={"Nuestra experiencia en fabricación nos permite producir una amplia gama de productos de fútbol americano, incluidos uniformes, cascos, artículos de entrenamiento en el campo y souvenirs. Utilizamos materiales de alta calidad y técnicas avanzadas para garantizar que cada pieza sea duradera y funcional, brindando a los atletas el equipo que necesitan para rendir al máximo."}
                            SecondPart={"¿Lo que ofrecemos?"}
                            LastPart={"Uniformes de Fútbol Americano: Confeccionados con tejidos de alta tecnología para mayor durabilidad y comodidad.Cascos y equipo de protección: Diseñados para la seguridad y el rendimiento.Suministros de entrenamiento de campo: incluidos conos, escaleras de agilidad y más. Souvenirs y Merchandising: Productos personalizados para fans y seguidores."}
                            URL={"https://truechoice.mx/img/comercializacion.jpg"}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="card-container">
                        <CardService
                            Title={"Importacion / Exportacion"}
                            FirstPart={"Nuestra experiencia en fabricación nos permite producir una amplia gama de productos de fútbol americano, incluidos uniformes, cascos, artículos de entrenamiento en el campo y souvenirs. Utilizamos materiales de alta calidad y técnicas avanzadas para garantizar que cada pieza sea duradera y funcional, brindando a los atletas el equipo que necesitan para rendir al máximo."}
                            SecondPart={"¿Lo que ofrecemos?"}
                            LastPart={"Uniformes de Fútbol Americano: Confeccionados con tejidos de alta tecnología para mayor durabilidad y comodidad.Cascos y equipo de protección: Diseñados para la seguridad y el rendimiento.Suministros de entrenamiento de campo: incluidos conos, escaleras de agilidad y más. Souvenirs y Merchandising: Productos personalizados para fans y seguidores."}
                            URL={"https://truechoice.mx/img/import-export.jpg"}/>
                        </Col>
                        <Col className="card-container">
                        <CardService
                            Title={"Personalizacion"}
                            FirstPart={"Nuestra experiencia en fabricación nos permite producir una amplia gama de productos de fútbol americano, incluidos uniformes, cascos, artículos de entrenamiento en el campo y souvenirs. Utilizamos materiales de alta calidad y técnicas avanzadas para garantizar que cada pieza sea duradera y funcional, brindando a los atletas el equipo que necesitan para rendir al máximo."}
                            SecondPart={"¿Lo que ofrecemos?"}
                            LastPart={"Uniformes de Fútbol Americano: Confeccionados con tejidos de alta tecnología para mayor durabilidad y comodidad.Cascos y equipo de protección: Diseñados para la seguridad y el rendimiento.Suministros de entrenamiento de campo: incluidos conos, escaleras de agilidad y más. Souvenirs y Merchandising: Productos personalizados para fans y seguidores."}
                            URL={"https://truechoice.mx/public/img/pers.jpg"}/>
                        </Col>
                    </Row>
                    
                </div>
            </section>
            <footer>
                <Footer/>
            </footer>
        </main>
    )
}
export default Servicios;