import '../assets/css/CardService.css';

function CardService({ Title, FirstPart, SecondPart, LastPart, URL }) {
    return (
        <article 
            className="card-service" 
            style={{ backgroundImage: `url(${URL})` }}
        >
            <h1>{Title}</h1>
            <p>{FirstPart}</p>
            <p>
                <b>{SecondPart}</b><br />
                {LastPart}
            </p>
        </article>
    );
};

export default CardService;
