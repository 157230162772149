import { Row,Col, Card, CardGroup } from "react-bootstrap";
import '../assets/css/OneCards.css'
function OneCards(){
    return(
      <CardGroup className="the-one-container">
          <Card className="top"></Card>
          <Card className="top-left"></Card>
          <Card className="top-rigth"></Card>
          <Card className="bottom-left"></Card>
          <Card className="bottom-rigth"></Card>
          <img className="img-done" src="https://truechoice.mx/public/img/colord1.png" alt="TheOne" />
      </CardGroup>
    
    );
}
export default OneCards;