import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

//components
import NavBar from "./components/NavBar";
import HomePage from './components/HomePage';
import Servicios from './components/Servicios';
import Nosotros from './components/Nosotros';
import Contact from './components/Contacto';
import ScrollToTop from './components/ScrollToTop'  // Asegúrate de que la ruta sea correcta

//css
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/App.css'

function App() {
  return (
    <div className="App">
      <Router>
        <NavBar />
        <ScrollToTop/>
        <main>
          <div>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/Nosotros" element={<Nosotros />} />
              <Route path="/Servicios" element={<Servicios />} />
              <Route path="/Contacto" element={<Contact />} />
            </Routes>
          </div>
        </main>
      </Router>
    </div>
  );
}

export default App;
