import { Card, CardGroup,Col,Row } from 'react-bootstrap';

//css
import '../assets/css/containerCards.css'
function ContainerCards() {
  return (
    <CardGroup>
        <Row>
            <Col>
                <Card className="bg-dark text-white large">
                    <Card.Img src="https://truechoice.mx/public/img/bolsa.webp" alt="Card image" />
                    <Card.ImgOverlay>
                        <Card.Title style={{ fontSize:'2rem'}}>Duffel Bag</Card.Title>
                    </Card.ImgOverlay>
                </Card>
            </Col>
            <Col>
            <Card className="bg-dark text-white">
                    <Card.Img src="https://truechoice.mx/public/img/indicator.webp" alt="Card image" />
                    <Card.ImgOverlay style={{display : 'flex', alignItems:'end'}}>
                        <Card.Title style={{ color: 'white', fontSize:'2rem'}}>Dial- Down indicator</Card.Title>
                    </Card.ImgOverlay>
                </Card>
            </Col>
            <Col>
                <Card className="bg-dark text-white">
                    <Card.Img src="https://truechoice.mx/public/img/wheels.jpg" alt="Card image" />
                    <Card.ImgOverlay>
                        <Card.Title style={{ fontSize:'2rem'}}>Tackling Wheels</Card.Title>
                    </Card.ImgOverlay>
                </Card>
            </Col>
        </Row>
        <Row>
            <Col>
                <Card className="bg-dark text-white large">
                    <Card.Img src="https://truechoice.mx/public/img/set.jpg" alt="Card image" />
                    <Card.ImgOverlay>
                        <Card.Title style={{ fontSize:'2rem'}}>CHAIN SET</Card.Title>
                    </Card.ImgOverlay>
                </Card>
            </Col>
            <Col>
            <Card className="bg-dark text-white">
                    <Card.Img src="https://truechoice.mx/public/img/indicatordigital.jpg" alt="Card image" />
                    <Card.ImgOverlay style={{display : 'flex', alignItems:'end'}}>
                        <Card.Title style={{ fontSize:'2rem'}}>Digital Indicator</Card.Title>
                    </Card.ImgOverlay>
                </Card>
            </Col>
            <Col>
                <Card className="bg-dark text-white">
                    <Card.Img src="https://truechoice.mx/public/img/man.jpg" alt="Card image" />
                    <Card.ImgOverlay style={{display : 'flex', alignItems:'end'}}>
                        <Card.Title style={{ fontSize:'2rem'}}>Man Shield</Card.Title>
                    </Card.ImgOverlay>
                </Card>
            </Col>
        </Row>
    </CardGroup>
  );
}

export default ContainerCards;